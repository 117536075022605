// Footer
.footer {
    padding: 7rem 4rem 6rem;
    background-color: fade(@gold, 15%);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media @tablet{
        padding: 4rem 2rem;
    }
    @media @mobile {
        padding: 1.25rem 1rem;
    }
}

.footer__info {
    display: flex;
    max-width: 1024px;
    margin: 0 auto;    
    @media @tablet {
        display: block;
        text-align: center;
    }

    
    .info__col {
        flex: 1 0;
        padding-right: 1rem;
        @media @tablet {
            padding: 0 2rem 1rem;
        }
        
        p {
            font-size: 0.9rem;
            line-height: 1.65rem;
            font-weight: 300;

            @media @tablet{
                font-size: 1rem;
            }

            @media @mobile{
                font-size: 0.8rem;
            }
            
            strong{
                font-weight: 500;
            }

            a {
                color: inherit;
                text-decoration: none;
                cursor: pointer;

                &:hover{
                    text-decoration: underline;
                }
            }
        }

        .col--name {        
            font-size: 1.19rem;
            font-weight: 500;  
            
            @media @tablet{
                font-size: 1.4rem;
            }

            @media @mobile{
                font-size: 1.19rem;
            }
        } 

        .hours{
            min-height: 4rem;  
            
            @media @tablet{
                min-height: initial;
            }
        }

        .footer__links {
            display: flex; 
            align-items: center; 
            
            @media @tablet{
                margin-top: 2rem;
                justify-content: center;
            }

            @media @mobile{
                display: block; 
            }
                      
            .footer__link {
                margin-right: 3.75rem;    
                display: block;

                &:last-child{
                    margin-right: 0;
                }

                @media @mobile{
                    margin: 1rem 0 0;
                }
            }
            
            .footer__link:nth-child(odd) {
                img {
                    width: 6rem;
                    height: auto;
                }

                @media @mobile {
                    img {
                        width: 5rem;
                    }
                }

            }

            .footer__link:nth-child(even) {
                img {
                    width: 13.1rem;
                    height: auto;
                }
                
                @media @mobile {
                    img {
                        width: 11rem;
                    }
                }
            }
        }    
    }
    
}

.footer__dev {
    text-align: center;
    font-weight: 300;
    padding-top: 2rem;
    font-size: 0.875em;

    @media @tablet {
        text-align: center;
        padding-top: 1rem;
    }
}