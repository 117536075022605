body .hero {
    display: flex;
    height: auto;
    min-height: 22.5rem;   
    position: relative;
    @media @small {        
        min-height: 32.5rem;
    }
    @media @normal {
        min-height: 90vh;        
    }
    &::after {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #000;
        //opacity: 0.2;
        opacity: 0;
        z-index: 10;
    }

    &__content{
        z-index: 30;
        position: relative;
        bottom: inherit;
        width: 100%;
        padding: 5em 1rem;
        padding-top: calc(~"60px + 5rem");
        // padding-top: 5.5em;
        padding-bottom: 1em;
        color: @white;  
        text-align: center;
        min-height: 22.5rem;
        justify-content: center;
        max-width: @text-width;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        @media @normal {
            height: 75vh;
            //padding-top: 6.56rem;         
        }

        h1 {
          color: @white; 
          margin: 0;
          font-size: 2.5rem; 
          line-height: 1.2;
          margin-bottom: 1.5rem;
          font-weight: 500;
          letter-spacing: 0;
          text-transform: none; 

          @media @small { 
            font-size: 3.5rem;  
            margin-bottom: 2rem;    
          }

          @media @normal {
            font-size: 4.5rem;                           
          }

          .long-text{
            font-size: 2rem; 
            line-height: 1.1; 

            @media @small { 
                font-size: 3rem;  
                 
              }
    
              @media @normal {
                font-size: 4rem;                                          
              }
          }

          strong {
              display: block;
          }
        }
    }

    &__text {
        margin: 0  0 1.5rem;
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 1.3rem;

        @media @small { 
            font-size: 1.1rem;
            line-height: 1.63rem;
            margin: 0  0 2rem;
        }

        @media @normal { 
            font-size: 1.19rem;            
        }

        &:empty {
            display: none;
        }
    }

    &__info {
        
        .form-group {
            margin-top: 0.3rem;
            justify-content: center;
            font-size: 1rem;                
            letter-spacing: 1px;
            line-height: 1.3rem;

            @media @small { 
                font-size: 1.1rem;
                line-height: 1.63rem;               
            }

            @media @normal { 
                font-size: 1.19rem;                           
            }

            &.info-text{
                margin: 1rem 0 0;   

                &:empty {
                    display: none;
                }
            }

            &:first-of-type {
                margin-top: 0;
            }

            span.EditingFormErrorLabel {
                font-size: 1rem;
                font-weight: 500;
                letter-spacing: 1px;
                @media @small { 
                    font-size: 1.19rem;
                }
            }

            span.EditingFormControlNestedControl {               
                font-weight: 300;
                letter-spacing: .7px;               
            }
            
        }
        

        .btn-container {
            .FormButton {
                margin-top: 1.5rem;
                @media @normal {
                    margin-top: 2.5rem;
                }
                a {
                    background-color: @white;
                    padding: 0.6em 2.5rem;
                    color: @bl;
                    font-weight: 500;
                    font-size: 1.2em;
                    letter-spacing: 0;
                    transition: all 0.3s;
                    text-decoration: none;
                    display: inline-block;
                    @media @small { 
                        font-size: 1.3em;
                    }
                    @media @normal {
                        font-size: 1.44em;
                        padding: .9em 5rem;
                    }

                    &:hover {
                        color: @white;
                        background-color: #fbba00;
                    }
                    &:hover,
                    &:focus {
                        outline: none;
                    }
                }   
            }
        }
    }

    &-bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            font-family: 'object-fit: cover';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
    }

    .background-filter{
        padding: 1.3rem;
        background-color: fade(#000, 50%);
        display: inline-block;

        @media @small { 
            padding: 2rem;
        }
    }

}

.mobileMenu {
   .main {
   } 
}