body, html {
    color: @goldML;
    margin: 0;
    scroll-behavior: smooth;
}

* {
    font-family: Motif,-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
}

body.mobileMenu{
    overflow: hidden;
    position: fixed;
    width: 100%;
}

* {
    box-sizing: border-box;
}

.h1 {
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
}

h2 {
    color: @goldML;
    text-transform: uppercase;
}

h4 {
    //.font-special();
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
}

p {
    color: @goldML;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.67;

    &.lead {
        font-size: 17px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.25em;
        text-align: center;
        color: @goldML;
    }
}

a {
    color: @black;
    font-weight: 400;
    letter-spacing: 0.025em;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}

.btn {
    padding: .6em 0;
    font-size: 1.12rem;
    letter-spacing: .1em;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    min-width: 180px;
    padding-left: .75rem;
    padding-right: .75rem;
    transition: all .2s;
    @media @mobile {
        background: transparent;

        &:after {
            display: none;
        }
    }

    svg {
        transform: rotate(-90deg) translate3d(0, 0, 0);
        transition: all .3s;
        height: 19px;
        width: 23px;
        margin-left: 0; //.5rem;
    }

    &:hover {
        svg {
            transform: rotate(-90deg) translate3d(0, .5rem, 0);
        }
    }

    &:after {
        display: none;
    }
}

.btn-mobile {
    display: none;
    @media @mobile {
        display: block;
    }
}

.btn-black {
    font-size: 22px;
    color: @blue-text;
    font-weight: 500;
    background: transparent;
    text-transform: none;
    svg {
        path {
            fill: @gold;
        }
    }
    &:hover {
        background: transparent;
    }
}

.btn-white {
    background: white;
    padding: 1.2rem 1.75rem;
    font-size: 1.17rem;
    letter-spacing: .1em;
    outline: none;
    cursor: pointer;
    font-weight: 400;
    border: none;
    -webkit-appearance: none;
    border-radius: 0;

    &:hover {
        background: @btn-yellow;
        color: white;
    }

    @media @mobile{
        font-size: 1rem;
        padding: 1rem;
    }
}

.btn-block {
    display: block;
    width: fit-content;
    margin: 0 auto;
}

.btn-wide {
    padding: 1em 3.25em;
}

.btn-gold {
    .nice-button-styles();
    border-top-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    padding: 1.5rem 2.5em !important;
    box-shadow: none;
    width: auto;
    max-width: 30rem;
    -webkit-appearance: none;
    text-decoration: none;
    font-size: 22px;
    font-weight: 500;
    text-transform: none;
    transition: all .2s;
    line-height: 1.7rem;
    &:hover {
        cursor: pointer;
    }
    &:focus {
        outline: none;
    }
}

.btn-disabled {
    background: @light-gray !important;
    color:  @white !important;
    cursor: not-allowed !important;
    border: 1px solid @light-gray !important;
    &:hover {
        background: @light-gray !important;
    }
}

.btn-close {
    position: absolute;
    right: 0;
    top: 0;
    background-image: url('/Lyceum/img/close.png');
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position: 100% 0;
    padding-right: 1.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    &:focus {
        outline: none;
    }
}

.btn-tr {
    font-weight: 500;
    background: @blue-dark;
    color: @white;
    &:hover {
        background: darken(@blue-dark, 10%);
    }
    @media @mobile {
        width: 100%;
    }
}

.form-group .EditingFormLabel {
    font-weight: 500;
}

input,
textarea,
.DropDownField {
    &.form-control {
        border-color: @gold;
    }
}

.mobile-logo {
    img {
        width: 31px;
        height: 31px;
    }
}

input.form-control,
textarea.form-control,
.DropDownField.form-control {
    font-size: 1em;
    letter-spacing: 0.05em;
    font-weight: 400;
    display: inline-block;
    padding: 1rem .75rem;
    border: 1px solid @goldML;
    width: 235px;
    border-radius: 0;
    -webkit-appearance: none;
    @media @mobile {
        width: 100%;
        font-size: 1rem !important;
        &:focus {
            font-size: 1rem !important;
            background: @white;
        }
    }
    &:focus {
        outline: @goldML solid .5px;
    }
    // &::-webkit-input-placeholder {
    //     /* WebKit, Blink, Edge */
    //     color: @white;
    // }
    // &::-moz-placeholder {
    //     /* Mozilla Firefox 19+ */
    //     color: @white;
    //     opacity: 1;
    // }
    // &:-ms-input-placeholder {
    //     /* Internet Explorer 10-11 */
    //     color: @white;
    // }
    // &::-ms-input-placeholder {
    //     /* Microsoft Edge */
    //     color: @white;
    // }
    // &::placeholder {
    //     /* Most modern browsers support this now. */
    //     color: @white;
    // }
}

.text-center {
    text-align: center;
}

.text-light {
    font-weight: 300;
}

.text-semibold {
    font-weight: 500;
}

.text-bold {
    font-weight: 600;
}

.text-small {
    font-size: .9rem;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-3 {
    margin-top: 3rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}