.font-special() {
    font-family: 'TriviaSerifMedium';
    src: url('../../fonts/TriviaSerifMedium.eot');
    src: url('../../fonts/TriviaSerifMedium.eot?#iefix') format('embedded-opentype'), url('../../fonts/TriviaSerifMedium.woff2') format('woff2'), url('../../fonts/TriviaSerifMedium.woff') format('woff'), url('../../fonts/TriviaSerifMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

.font-normal() {
    font-family: 'Motif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.object-fit(@fit: fill) {
    -o-object-fit: @fit;
    object-fit: @fit;
    font-family: 'object-fit: @{fit}';
}

.ratio(@perc) {
    position: relative;

    &:before {
        content: '';
        float: left;
        padding-bottom: @perc;
    }

    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

.fill-parent() {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.square() {
    position: relative;

    &:before {
        content: '';
        float: left;
        padding-bottom: 100%;
    }

    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

.flex-col(@width) {
    flex: 0 0 @width;
    max-width: @width;
}

.full-width() {
    width: 100%;
    max-width: 100%;
    flex: initial;
    margin: 0;
    float: none;
    transform: none;
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    top: auto;
    opacity: 1;
}

.center() {
    margin-left: auto;
    margin-right: auto;
}

.clearfix() {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.side-padding {
    padding-left: @main-padding;
    padding-right: @main-padding;
}

.vertical-padding {
    padding-top: @main-vertical-padding;
    padding-bottom: @main-vertical-padding;
    @media @extramiddle {
        padding-top: @main-vertical-padding-desktop;
        padding-bottom: @main-vertical-padding-desktop;
    }
}

.nice-button-styles {
    background-color: @btn-yellow;
    border: 1px solid @btn-yellow;
    color: @white;
    border-top-left-radius: 2.5rem;
    border-bottom-right-radius: 2.5rem;
    padding: 1rem 1.5rem;

    &:hover {
        background-color: @white;
        color: @btn-yellow;
    }
}

.office-heading {
    font-size: 3rem;
    line-height: 3.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    margin-top: 0;
    padding: 0 1rem;
    width: 100%;
    @media @extramiddle {
        font-size: 4.5rem;
        line-height: 5rem; 
    }
}

.menuButton(@width: 48, @color: #fff, @lineHeight: 3px, @spaceHeight: 4px) {
    display: block;
    width: @width;
    @height: (3 * @lineHeight)+(2 * @spaceHeight);
    height: @height;
    cursor: pointer;
    //@position: (((3 * @lineHeight) + (3 * @spaceHeight)) - (3 * @lineHeight)) / 3;
    //transform: translateZ(0);
    //will-change: transform;

    .line,
    &:before,
    &:after {
        background-color: @color;
        content: '';
        display: block;
        height: @lineHeight;
        margin: @spaceHeight/2 20%;
        //margin-left: 20%;
        //transform: translate3d(0, @position + @spaceHeight, 0);
        transition: all 300ms ease;
        width: 60%;
    }

    //  &:before {
    //      transform: translate3d(0, @spaceHeight, 0);
    //  }

    //  &:after {
    //     transform: translate3d(0, -@spaceHeight, 0);
    // }

    &.active {
        &:before {
            transform: translate3d(0, @spaceHeight - @lineHeight*2/3, 0) rotate(45deg) ;
            //background-color: @color;
        }

        &:after {
            transform: translate3d(0, -@spaceHeight + @lineHeight*2/3, 0) rotate(-45deg) ;
            //background-color: @color;
        }

        .line {
            opacity: 0;
        }
    }
}

.loop(@i: 0) when (@i < 10) {
    &:nth-child(@{i}) {
        order: @i;
    }

    .loop((@i + 1));
}

.form-icon() {
    &:before {
        content: "";
        width: 18px;
        height: 18px;
        position: absolute;
        top: 17px;
        left: 17px;
        background-position: center center;

        @media @mobile {
            width: 14px;
            height: 14px;
            top: 19px;
            left: 14px;
            background-size: contain;
        }
    }

    input,
    select {
        padding-left: 3rem;

        @media @mobile {
            padding-left: 2.25rem;
        }
    }
}
