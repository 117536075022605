.dd-options {
    width: 500px !important;
    .dd-option {
        display: flex;
        align-items: center;
        max-height: 60px;
    }
}

.dd-selected {
    display: flex !important;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
}
