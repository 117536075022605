.student-form {
    padding-top: 150px;
    &__wrap {
        margin-bottom: 8rem;
        h1 {
            max-width: 800px;
            margin: 0 auto;
            text-align: center;
            .side-padding();
            .office-heading();
            padding-top: 2rem;
            font-weight: 300;
            font-size: 3rem !important;
            margin-bottom: 0;

            a {
                font-weight: 300;
            }
            a:hover {
                text-decoration: underline;
            }
        }
        h2 {
            font-weight: 300;
            margin-top: 0;
            text-align: center;
            font-size: 2.25rem;
            text-transform: none;
            a {
                font-weight: 300;
            }
        }

        > div {
            max-width: @content;
            margin: 0 auto;
        }
        
        .student-form__message {
            text-align: center;
            margin-bottom: 2rem;
            padding-left: 4rem;
            padding-right: 4rem;

            
            @media @extramiddle {      
                padding-left: 3rem;
                padding-right: 3rem;
            }


            @media @mobile {         
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }
    }

    &__student {
        position: relative;
    }


    &__form {
        padding: 2rem 6rem;
        position: relative;
        background-color: @background-gray; 

        @media @extramiddle {          
            padding: 2rem 3rem;
        }


        @media @mobile {          
            padding: 2rem 2rem;
        }

        #slider {
            width: 100%;
            
        }

        h2, h3 {
            font-weight: 300;
            text-transform: none;
        }
        h2 {
            margin-bottom: 3rem;
            margin-top: 0;
        }
    }

    &__item {
        margin-bottom: 2rem;
        .EditingFormDescription, .EditingFormLabel {
            display: block;
            font-size: 15px;
            font-weight: 300;
        }
        .EditingFormDescription {
            margin-top: 0.5rem;
        }
        .EditingFormLabel {
            margin-bottom: 0.5rem;
        }
        input[type=text] {
            padding: .8rem;
            width: 100%;
            border: none;
            line-height: 1.15;
            box-sizing: border-box;
            font-size: .9rem;
        }

        &--range {
            display: flex;
            justify-content: center;
            align-items: center;

            #demo {
                padding: 0.5rem 1.5rem;
                background-color: #fff;
                display: inline-block;                
                margin-left: 5rem;
                min-width: 6.5rem;
                text-align: center;

                @media @extramiddle {
                    margin-left: 2rem;
                }
            }
        }
        &--date {
           
           a {
               display: none!important;
           } 

           .control-group-inline {
               display: flex;
           }

           button {
            line-height: 1.15;
            font-size: 1.5rem;
            padding: 0.5rem 2rem;
                span {
                    line-height: 1.15;
                    font-size: 1.5rem;
                    
                }
           }
        }

        &--condition {
            h3 {
                font-size: 1.5rem;
                margin: 0 0 1rem 0;
                line-height: 1.2;
            }
            p {
                font-size: 1.1rem;
                line-height: 1.3rem;
                font-weight: 300;
                margin: 0;
            }
        }

        &--multiple {
            display: flex;
            .dd-select {
                height: 100%;
            }
            .phonenumber__wrap {
                width: 100%;
                margin: 0 0 0 20px;
                position: relative;
            }
            .phonenumber__callingcode {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: .8rem;
                margin-right: 20px;
                font-size: .9rem;
            }
        }
    }

    &__section {
        display: flex;
        justify-content: space-between;

        @media @mobile{
            flex-direction: column;
        }
    }

    &__part {
        width: 45%;   
        
        @media @mobile{
            width: 100%; 
        }
        &:last-child {
            margin-bottom: 0;
        }
        h2 {
            font-size: 2.5rem;
            margin: 0 0 2rem 0;
        }

        .select-items {
            top: 0;
        }
    }

    &__terms {
        display: flex;
        margin-top: 60px;
        font-weight: 300; 
        &--title {
            font-size: 15px;       
        }
        &--titlesingle {
            display: block;
            padding-top: 7px;
        }
        label {
            cursor: pointer;
        }
        &--text {
            font-size: 13px;
        }
    }

    textarea {
        width: 100%!important;
        resize: vertical;
        min-height: 20rem;
        display: block;
        margin: 0;
        padding: .8rem;
        border: none;
        box-shadow: none;
        font-size: .9rem;
    }
    input[type=text],
    textarea {
        -webkit-appearance: none;
        border-radius: 0;
    }

    input[type=text],
    textarea {
        &:focus {
            outline: none;
        }
    }

    .hidden {
        display: none!important;
    }


    .transparent {
        opacity: 0;
    }
}

.EditingFormErrorLabel {
    color: red;
    padding-top: 0.5rem;
    display: block;
    font-size: .8rem;
}

.custom-select {
    position: relative;
    font-size: .9rem;
    select {
        display: none;
    }  

    .select-selected {
        background-color: #e2e2e2;
        color: #000;
        position: relative;    
        line-height: 1;
        &::after {
            position: absolute;
            content: "";
            top: .7rem;
            right: 2rem;
            width: .6rem;
            height: .6rem;
            transform: rotate(45deg);
                
            border: solid @text;
            border-width: 0 2px 2px 0;
            border-width: 0 2px 2px 0;
            
        }

        &.select-arrow-active {
            &:after {
                transform: rotate(-135deg);
                top: 1.1rem
          }
        }
    }
}
  
  
.select-items div,
.select-selected {
    color: #000;
    padding: .8rem;
    border: 1px solid transparent;
    cursor: pointer;
    line-height: 1.15;
}
  
.select-items {
    position: relative;
    background-color: #e2e2e2;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}
  
.select-hide {
    display: none;
}
  
.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
} 

.ccb {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;   
    border: none;
    border-radius: 0;
    min-width: 16px;
    min-height: 16px;
    background-color: white;
    margin-left: 1rem;
    display: inline-block;

    @media @formIpad{
        right: 0;
        position: absolute;
    }

    &:focus {
        outline: none;
    }
}


.custom-checkbox {
    position: relative;
    input[type=checkbox] {
        .ccb();
        width: 24px;
        height: 24px;
        margin-left: 0;
        margin-right: 2rem;
        cursor: pointer;
        -webkit-appearance: none;
        border-radius: 0;
        border: none;

        &:checked::after {
            content: "";
            width: 24px;
            height: 24px;
            background: url(/FaT/img/svg/ft-checked.svg) no-repeat center center;
            background-size: 20px 20px;
            cursor: pointer;
            top: 4px;
            margin-right: 2px;
            display: block;
            position: absolute;
            padding: 0px;
        }

        @media @mobile{
            margin-right: 1rem;
        }
    }
}

.custom-checkboxlist {
    position: relative;
    font-size: .9rem;
    &--value {
        background-color: #e2e2e2;
        position: relative;
        color: #000;
        cursor: pointer;
        line-height: 1;
        padding-right: 3.5rem;
        
        &::after {
            position: absolute;
            content: "";
            top: .7rem;
            right: 2rem;
            width: .6rem;
            height: .6rem;
            transform: rotate(45deg);
                
            border: solid @text;
            border-width: 0 2px 2px 0;
            border-width: 0 2px 2px 0;
            
        }

        &.select-arrow-active {
            &:after {
                transform: rotate(-135deg);
                top: 1.1rem
          }
        }
    }
    &--items {
        top: 100% !important;
        line-height: 2rem;
        div.d-flex {
            display: flex;
            align-items: center;           
            padding: .8rem .8rem .8rem 0;
            width: 100%;
            position: relative;
            cursor: default;
            &:hover {
                background: none;
            }
        }
        span.d-flex {
            display: flex;

            @media @formIpad{
                flex-direction: column;
            }

            & > div {
                width: 50%;
                &:first-of-type{
                    width: 44%;
                }
                &:last-of-type{
                    width: 66%;
                }

                @media @formIpad{
                    width: 100% !important;                   
                }
            }

            
        }
        label {
            display: inline-block;
            cursor: pointer;
        }
        input[type="checkbox"] {
            .ccb(); 
            cursor: pointer;
        }
        &.select-items div:hover {
            background: none;   
        }

        > label{
            width: 100%;
            border-top: 10px solid #f0f0f0;
            padding: .3rem 0 .3rem 1rem;
        }
    }
    input[type="checkbox"]:checked {
        &::after {
            content: "";
            width: 16px;
            height: 16px;
            background: url(/FaT/img/svg/ft-checked.svg) no-repeat center center;
            background-size: 14px 14px;
            cursor: pointer;
            top: 50%;
            transform: translate(0px, -50%);
            margin-right: 2px;
            display: block;
            position: absolute;
            padding: 0px;
        }
    }
}

.student__payment {
    line-height: 2rem;
    width: 60%; 
    margin: 0 auto;
    input[type=text] {
        background: rgba(255,255,255, .6);
        border: 2px solid white;
        height: 2.5rem;
        width: 7rem;
        margin-right: .5rem;
        color: black;
        text-align: center;
        font-size: 1.2rem;
        font-weight: 600;
        &:focus {
            outline: none;
        }
    }
    &--price {
        .currency {
            position: absolute;
            line-height: 3rem;
        }
        .error {
            display: block;
            color: red;
        }
    }
}