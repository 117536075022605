@scrollAnimation: .3s;

.header{
    position: relative;
    z-index: 100;   
    @media @tablet {
        position: static;
    }
}

.mobile-header{
    display:none;
    background-color: fade(@white, 90%);   
    padding: 1.1rem 2rem;     
    transition: @scrollAnimation;
    z-index: 1001;
    position: fixed;
    top: 0;
    left:0;
    right: 0;
    justify-content: space-between;
    
    @media @tablet {
        display: flex;
    }

    @media @mobile{
        padding: 1rem;  
    }

    .mobile-logo{
        display: block;
        
        img{
            width: 9rem;
            height: auto;
            
            @media @mobile{
                width: 7rem;
            }
        }
    }

    .mobile-toggle{
        z-index: 1000;        
        .menuButton(36px, @yellow, 3px, 10px);
    }
}


.nav{
    background-color: fade(@white, 90%);        
    position: fixed;
    width: 100%;
    z-index: 49; // padding-right: 300px;
    transition: all @scrollAnimation;
    top:0;
    left: 0;  
   
    
    @media @tablet {           
        right: 0;
        transform: translate(0, -100%);
        overflow-y: scroll;
        margin: 0;
        padding-top: 6rem;
        height: 100%;

        &.open {
            transform: translate(0, 0);
        }
    }
    
    .nav__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;       
        max-width: 1400px;
        position: relative;
        padding: 1.25rem 2rem;
        margin: 0 auto;
       
        @media @tablet {
            display: block;  
            text-align: center;
                  
        }

        .header__logo__wrapper{
            display: flex;
            align-items: center;
            flex: 1 1 auto;

            @media @tablet{
                display: none;
            }

            .header__logo{
                img{
                    width: 13.1rem;
                    position: static;
                    margin-right: 1rem;
                }
            }

            .header__logo__text{
                font-size: .94rem;
                text-decoration: none;
            }    
            
            .header__logo__text:hover {
                text-decoration: underline;
            }  
            
            
        }

        .header__text{
            margin: 0;
            font-size: 1.44rem;
            font-weight: 500;
            flex: 1 1 auto;
            text-transform: none;

            @media @mobile{
                font-size: 1.5rem;
            }
        }

        .lang-selector{
            cursor: pointer;
            list-style-type: none;
            padding: 0;
            flex: 1 1 auto;
            text-align: right;
            margin: 0;
            font-size: .94rem;
            
            @media @tablet {
                margin-top: 2rem;
                text-align: center;
            }

            .language-item{
               
                display: inline-block;
                padding-right: 1.5rem;
                text-transform: uppercase;
                font-weight: 500;    
                
                &:last-child{
                    padding:0;
                }

                a{
                    color: @goldML;
                    text-decoration: none;

                    &:hover{
                        color: @yellow;
                    }
                }
            }
        }        
    }
}

